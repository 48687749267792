<template>
  <div class="user_center_component">
    <div class="user_head">
      <div class="user_avatar">
        <!-- <img v-if="userInfo" :src="userInfo.head_img" alt /> -->
        <img src="../../assets/imgs/icon_head.jpg" alt />
        <div v-if="userInfo">
          <div class="phone_num">
            {{ userInfo.phone }}
          </div>
          <div>
            <span v-if="userInfo.province">{{ userInfo.province }}</span
            >
            <!-- / <span v-if="currentYear">{{ currentYear }}</span> -->
            <span v-if="userInfo.type && !userInfo.is_new">/{{
              userInfo.type
            }}</span>
            <span v-if="userInfo.subject_str && userInfo.is_new">{{
              userInfo.subject_str
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="user_center_nr">
      <ul>
        <li class="my_test">
          <div class="nr">
            <b>我的<br />测评</b>
            <div class="test_nr" v-if="userInfo">
              <!-- <span class="line"></span> -->
              <span
                class="test"
                v-if="userInfo.test_status.zhiye"
                @click="fetchTestResult('one')"
                >职业规划测评</span
              >

              <span
                class="test course_test"
                v-if="userInfo.test_status.gaokao"
                @click="fetchTestResult('second')"
                >选科测评</span
              >

              <span
                class="test"
                v-if="userInfo.test_status.xuexi"
                @click="fetchTestResult('third')"
                >学习状态测评</span
              >
            </div>
          </div>
        </li>
        <li class="score">
          <div class="info">
            <b>平时<br />成绩</b>
            <div>
              <!-- <span class="line"></span> -->
              <span  class="point_num"
                >
                
                {{ scoreHistory.score.predict_score?scoreHistory.score.predict_score:scoreHistory.score.real_score }}分  {{scoreHistory.score.course.replace (",","/").replace (",","/")}}
                </span
              >
            </div>
          </div>
          <div>
            <span class="change_score" @click="editScore">更换</span>
          </div>
        </li>
        <li class="my_zhiyuan">
          <div
            class="nr"
            v-if="tableData.length > 0"
            @click="handleClick(tableData[0])"
          >
            <div class="info">
              <b>我的<br />志愿</b>
              <div>
                <!-- <span class="line"></span> -->
                <div>
                  <div>{{ tableData[0].name }}</div>
                  <div class="gray sub_info">
                    <span v-if="!userInfo.is_new">{{ tableData[0].type }}</span
                    ><span>{{ tableData[0].score1 }}分</span>
                    <span>{{ tableData[0].course }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <span>靠谱率 {{ tableData[0].probability }}</span>
              <i style="vertical-align: text-bottom;" class="el-icon-arrow-right"></i>
            </div>
          </div>
          <div
            class="all_zhiyuan"
            v-if="this.tableData.length > 0"
            @click="toZhiyuanList"
          >
            查看全部({{page.total }})
          </div>
          <div v-else class="nr">
            <div class="info">
              <b>我的<br />志愿</b>
            </div>
          </div>
        </li>
      </ul>
      <div class="login_out">
        <el-button type="primary" @click="loginOut()">退出</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'userCenter',
  data() {
    return {
      tableData: [],
      zhiyuanName: '',
      type: '',
      page: {
        page: 1,
        total: 0,
      },
      userInfo:null,
      scoreHistory:null,//历史成绩
    };
  },
  computed: {
    ...mapState({
      // userInfo: (state) => state.userinfo,
      currentProvince: (state) => state.currentProvince,
      currentYear: (state) => state.currentYear,
      isNew: (state) => state.isNew,
      // isAuthed: state => (state.userinfo ? true : false),
      // notify: state => state.notify,
      // favorates: state => state.favorates,
      // histories: state => state.histories
    }),
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))|| [];
    console.log("====sss",this.userInfo);
    // this.getMyVolunteer();
    this.getPlanLish();
    this.getScoreHistory();
  },
  methods: {
    loginOut() {
      console.log(window, '====loginOut', this.$router);
      localStorage.clear();
      sessionStorage.clear();
      setTimeout(() => {
        window.location.href = '/';
      }, 500);
      this.$cookies.remove('yzy-token');
      localStorage.removeItem('scoreInfoData');
      localStorage.removeItem('localProvince');
      localStorage.removeItem('storeCurrentProvince');
      localStorage.removeItem('userInfo');
      localStorage.removeItem('storeCurrentProvince');
      localStorage.removeItem('localProvince');
      localStorage.removeItem('localProvinceInfo');
      // this.$router.push({ push: "/" })
    },
    editScore() {
      this.$router.push({ path: '/accounts/createScore' });
    },
    //查询成绩
    async getScoreHistory() {
      const apiUrl = '/php/user.score.get'; //'php/score.config.get';
      this.$toast({
              type: "loading",
              message: "loading...",
              overlay:true,
              duration:0,
            });
      const res = await this.$axios.get(apiUrl);
      if (res.data.ret === 0) {
        this.scoreHistory = res.data.data;
      }
      setTimeout(() => {
        this.$toast.clear();
      }, 1000);
    },
    //我的志愿
    async getPlanLish() {
      const res = await this.$axios.get(
        `/php/user.plan.list?page=${this.page.page}`
      );
      console.log(111,res);
      this.page.total = res.data.data.total;
      this.tableData = res.data.data.list;
    },
    async getMyVolunteer() {
      const apiUrl = '/api/volunteer/';
      try {
        const response = await this.$axios.get(apiUrl);
        if (response.data.errno === 0) {
          this.tableData = response.data.results;
          this.zhiyuanName = '志愿表' + this.tableData.length;
          this.tableData.forEach((item, index) => {
            this.$set(item, 'index', this.tableData.length - index);
          });
        }
      } catch (error) {
        console.log(`question error: ${error}`);
      }
    },
    toZhiyuanList() {
      this.$router.push({ name: 'MyTablesList' });
    },
    handleClick(row) {
      // if (this.userInfo.is_new) {
      //   // 新高考，type是志愿表序号
      //   this.$router.push({
      //     name: 'MyTableDetail',
      //     params: { id: row.id, type: row.index + '-' + row.point },
      //   });
      // } else {
      //   this.$router.push({
      //     name: 'MyTableDetail',
      //     params: { id: row.id, type: row.tag + '-' + row.point },
      //   });
      // }
      this.$router.push('/tzy/details?id=' + row.id);
    },
    //获取测评结果
    async fetchTestResult(tabActiveName) {
      if (tabActiveName == 'second') {
        this.type = 'course';
      } else if (tabActiveName == 'third') {
        this.type = 'learning';
      } else {
        this.type = 'career';
      }

      this.$router.push({ name: 'MyEvaluations', params: { type: this.type } });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.user_center_component {
  font-size: 0.26rem;
  // min-height: 100vh;
  .user_center_nr {
    padding: 0 0.2rem;
    margin-bottom: 1rem;
    padding-bottom: 2rem;
    .my_test {
      > div {
        flex-basis: 100%;
      }
      .test_nr {
        flex-wrap: nowrap;
      }
    }
    ul {
      margin-top: 0.35rem;
      li.score,
      li.my_test {
        display: flex;
        align-items: center;
      }
      li.my_zhiyuan {
        .nr {
          margin: 0.3rem 0;
        }
        .all_zhiyuan {
          font-size: 0.2rem;
          height: 0.7rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0.3rem 0 0.1rem 0;
          border-top: 1px solid #e2e2e2;
          color: #666;
        }
      }
      li {
        min-height: 1.4rem;
        width: 100%;
        border-radius: 10px;
        background: #fff;
        margin-bottom: 0.4rem;

        padding: 0 0.3rem;
        div.nr {
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
          flex: 1;
        }
        div.info {
          &:nth-child(1) {
            flex: 1;
            display: flex;
            align-items: center;
          }
        }
        .point_num {
          margin-left: 0.1rem;
        }
        .el-icon-arrow-right {
          font-size: 0.38rem;
          color: #999;
        }
        .course_test {
          margin: 0 0.45rem;
        }

        .sub_info {
          span {
            font-size: 0.2rem;
            margin-right: 0.2rem;
          }
        }

        .change_score {
          display: inline-block;
          border: 1px solid #910000;
          border-radius: 10px;
          width: 1.1rem;
          font-size: 0.2rem;
          color: #910000;
          padding: 1px 0;
          text-align: center;
        }
        b {
          width: 1rem;
        }
        .line {
          display: inline-block;
          height: 1rem;
          margin: 0 0.12rem;
          border-right: 1px solid #ccc;
        }
      }
    }
    .login_out {
      width: 100%;
      margin-top: 0.5rem;
      margin-bottom: 1rem;
      .el-button--primary {
        width: 100%;
      }
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
  }

  .user_head {
    width: 100%;
    height: 3rem;
    background: #910000;
    padding: 0.5rem;
    color: #fff;
    .phone_num {
      font-size: 0.32rem;
      font-weight: 600;
      margin-bottom: 2px;
    }
    .user_avatar {
      margin-top: 0.3rem;
      display: flex;
      align-items: center;
    }
    img {
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 50%;
      margin-right: 14px;
      border: 1px solid #e8e8e8;
    }
  }
}
</style>
